:root {
    --cursor-size: 40px;
    --cursor-transform-on-click: .9;
    --cursor-transform-on-hover: 1.4;
}

html {
    --cursor-color: white
}

.cursor {
    & > div:nth-child(1) {
        background-color: transparent !important;
        border: $border-width-base solid var(--cursor-color) !important;
        transition: opacity 0.15s ease-in-out 0s, height 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, background-color 0.17s ease-in-out 0s !important;

        &[style*="width: 80px"] {
            background-color: var(--cursor-color) !important;
        }
    }
}
