.testimonial {
    &:before,
    &:after {
        content: '“';
        @extend .heading;
        @extend .heading--1;
        font-size: $font-size-giant;
        line-height: $font-size-giant;
        vertical-align: baseline;
        position: absolute;
        left: spacing(base);
        top: -(spacing(base));
        transition: opacity 400ms ease-in-out;
    }

    &:after {
        top: auto;
        right: spacing(base);
        bottom: calc(-#{spacing(base)} - (#{$font-size-giant} / 1.3));
        left: auto;
    }

    &__qoute {
        margin-bottom: spacing(base);
    }

    &__author {
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: $border-width-base;
            width: $font-size-small;
            margin-right: spacing(sm);
            background-color: var(--primary-color);
            position: relative;
            top: -($border-width-base / 2);
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .testimonial {

    }
}
