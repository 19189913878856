//REFACTOR

.container
{
    margin: 0 auto;
    //max-width: 100%;

    &--big
    {
        width: $container-big;
    }

    &--medium
    {
        width: $container-medium;
    }

    &--narrow
    {
        width: $container-narrow;
    }

    &--small
    {
        width: $container-small;
    }

    &--smaller
    {
        width: $container-smaller;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .container {
        max-width: 100%;
    }
}
