html
{
    .vertical-align
    {
        &--middle
        {
            vertical-align: middle;
        }

        &--top
        {
            vertical-align: top;
        }

        &--bottom
        {
            vertical-align: bottom;
        }
    }

    .align-items
    {
        &--center
        {
            align-items: center;
        }

        &--flex-end
        {
            align-items: flex-end;
        }

        &--flex-start
        {
            align-items: flex-start;
        }
    }

    .justify-content
    {
        &--center
        {
            justify-content: center;
        }

        &--flex-end
        {
            justify-content: flex-end;
        }

        &--flex-start
        {
            justify-content: flex-start;
        }
    }

    .align
    {
        &--left
        {
            text-align: left;
        }

        &--right
        {
            text-align: right;
        }

        &--center
        {
            text-align: center;
        }
    }

    .align-self
    {
        &--flex-end
        {
            align-self: flex-end;
        }
        &--flex-start
        {
            align-self: flex-start;
        }
    }

    .flex-direction {
        &--row {
            flex-direction: row;
        }

        &--column {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    html {
        .flex-direction-sm {
            &--row {
                flex-direction: row;
            }

            &--column {
                flex-direction: column;
            }
        }

        .justify-content-sm
        {
            &--center
            {
                justify-content: center;
            }

            &--flex-end
            {
                justify-content: flex-end;
            }

            &--flex-start
            {
                justify-content: flex-start;
            }
        }
    }
}
