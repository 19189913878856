body {
    .end-to-end {
        &__kickoff {
            &-image {
                max-width: 564px;
            }
        }

        &__day1 {
            &-pull-up {
                &-graphics {
                    height: 0;
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    z-index: 1;
                    overflow: visible;
                    transform: translateY(-511.5px);
                }

                &-text {
                    height: 233px;
                }
            }
            &-content {
                padding-top: spacing(lg);
                border-top: $border-width-base solid var(--primary-color);
            }
        }

        &__day2 {
            &-room {
                max-width: 629px;
            }
        }

        &__day5 {
            &-draw {
                justify-content: center;
                left: - $border-width-base;
            }
        }

        &__video {
            max-width: 1000px;
            margin-block: spacing(hg);

            &--wireframe-fix {
                border-right-width: 1px;
            }

            &--design {
                margin-bottom: 0;

                &-rounded {
                    height: 100%;
                    border-radius: 20px;
                    overflow: hidden;
                }
            }
        }

        &__concept-card {
            &--2 {
                transform: rotate(-12deg);
            }
            &--3 {
                transform: rotate(9deg);
                margin-top: -300px;
            }
        }

        &__design-systems {
            position: relative;
            padding-block: spacing(lg) 450px;

            &-bg {
                position: absolute;
                top: 0;
                z-index: -1;
                max-width: 100%;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                img {
                    max-width: none;
                }
            }
        }
    }
}

@media only screen and (min-width: container(max))
{
    body {
        .end-to-end {
            &__day5 {
                &-agile {
                    border-left: $border-width-base solid var(--primary-color);
                    border-right: $border-width-base solid var(--primary-color);
                }

                &-draw {
                    left: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    body {
        .end-to-end {
            &__team {
                &-image {
                    max-width: none;
                    width: 150vw;
                    transform: translateX(-33vw);
                }
            }
            &__day1 {
                &-pull-up {
                    &-graphics {
                        flex-direction: column;
                        align-items: center;
                        height: auto;
                        transform: none;
                        margin-top: -511px;
                        margin-bottom: -250px;
                    }
                }

                &-content {
                    padding-bottom: 0;
                }
            }

            &__video {
                margin-block: spacing(sm);

                &--design {
                    &-rounded {
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
