.chapter-title
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40vw;
    padding-top: 0;
    padding-block: spacing(base);
    text-align: center;

    > .heading {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: $screen-sm)
{
    .chapter-title
    {
        min-height: 50vh;
    }
}
