.page
{
    width: 100%;
    height: 100%;
    margin-inline: auto;
    position: relative;
    z-index: 1;
    background-color: var(--background-color);
}

@media only screen and (max-width: $screen-sm)
{
    .page {
        padding-top: spacing(md);
        overflow: hidden;
    }
}
