$colors: (
    black: (
        base: black
    ),
    white: (
        base: white
    ),
    grey: (
        base: #67676B,
        900: #1B1B1F,
        800: #363638,
        700: #464649,
        600: #57575A,
        500: #67676B,
        400: #7F7F83,
        300: #98989A,
        200: #B0B0B2,
        100: #C8C8CA,
        50: #D4D4D6,
        25: #F6F6F9
    ),
    brand: (
        bt: #5514B4,
        wrapless: #EF1020,
        quick-pay: #0848FF,
        linkedin: #2F65BD,
        spotify: #1ED760
    ),
    accent: (
        primary: #EF1020,
        secondary: yellow,
    ),
    khaki: (
        base: #3D4630,
        light: #C1CBAE,
        lighter: #D1E2C0,
        dark: #2c3222,
    ),
    lemon: (
        base: #FAFF7D,
        light: #FEFFDB,
        dark: #f9ff4a
    ),
    ivory: (
        base: #F9F0D9,
        light: #FDFDF0
    ),
    yellow: (base: yellow),
    orange: (base: orange),
    red: (base: #EF1020),
    pink: (base: pink),
    purple: (base: purple),
    blue: (base: blue),
    green: (base: green)
);

@function color($color, $tone: 'base')
{
    @return map-get(map_get($colors, $color), $tone);
}

@each $color-key, $color-value in $colors {
    @each $tone-key, $tone-value in $color-value {
        @if $tone-key == base {
            .color#{'--' + $color-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key} {
                background-color: $tone-value !important;
            }
        } @else {
            .color#{'--' + $color-key + '-' + $tone-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key + '-' + $tone-key} {
                background-color: $tone-value !important;
            }
        }
    }
}

:root {
    @each $color-key, $color-value in $colors {
        @each $tone-key, $tone-value in $color-value {
            @if $tone-key == base {
                --color#{'-' + $color-key}: #{$tone-value};
            } @else {
                --color#{'-' + $color-key + '-' + $tone-key}: #{$tone-value};
            }
        }
    }
}

:root {
    --color-on-body: #{color(white)};
    --background-color: #{color(grey, 900)};
    --primary-color: #{color(grey, 900)};
    --primary-color-negative: #{color(white)};
    --secondary-color: #{color(red)};
    --secondary-color-negative: #{color(white)};
    --color-accent-primary: #{color(red)};
    --color-accent-negative: #{color(white)};
    --highlight-bg-color: var(--color-accent-primary);
    --highlight-color: var(--color-accent-negative);
}

html {
    &[page="projects"] {
        --background-color: #{color(white)};
    }
    &[page="about"] {
        --background-color: #{color(grey, 800)};
        --primary-color: #{color(white)};
        --color-accent-primary: #{color(red)};
        --color-accent-negative: #{color(white)};
    }
    &[page="end-to-end"] {
        --background-color: #{color(grey, 25)};
        --color-accent-primary: #{color(brand, bt)};
        --color-accent-negative: #{color(white)};
    }
    &[page="wrapless"] {
        --background-color: #{color(grey, 25)};
        --color-accent-primary: #{color(brand, wrapless)};
        --color-accent-negative: #{color(white)};
    }
    &[page="quick-pay"] {
        --background-color: #{color(grey, 25)};
        --color-accent-primary: #{color(brand, quick-pay)};
        --color-accent-negative: #{color(white)};
    }
    &[page="error"] {
        --background-color: #{color(grey, 500)};
        --primary-color: #{color(white)};
        --color-accent-primary: #{color(lemon, dark)};
        --color-accent-negative: #{color(white)};
    }
    &[page="test"] {
        --background-color: #{color(grey, 500)};
        --primary-color: #{color(white)};
        --color-accent-primary: #{color(lemon, dark)};
        --color-accent-negative: #{color(white)};
    }
}
