.position
{
    &--relative
    {
        position: relative;
    }

    &--absolute
    {
        position: absolute;
    }

    &--fixed
    {
        position: fixed;
    }

    &--static
    {
        position: static !important;
    }
}
