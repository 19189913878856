$iconSizes: (
    xs: (
        desktop: .58rem,
        tablet: .58rem,
        mobile: .58rem
    ),
    sm: (
        desktop: 1rem,
        tablet: 1rem,
        mobile: 1rem
    ),
    base: (
        desktop: 1.5rem,
        tablet: 1.5rem,
        mobile: 1.5rem
    ),
    md: (
        desktop: 1.6rem,
        tablet: 1.6rem,
        mobile: 1.6rem
    ),
    lg: (
        desktop: 1.75rem,
        tablet: 1.75rem,
        mobile: 1.75rem
    ),
    xl: (
        desktop: 2rem,
        tablet: 2rem,
        mobile: 2rem
    ),
    2xl: (
        desktop: 4rem,
        tablet: 4rem,
        mobile: 4rem
    ),
    3xl: (
        desktop: 5rem,
        tablet: 5rem,
        mobile: 5rem
    ),
    4xl: (
        desktop: 6.25rem,
        tablet: 6.25rem,
        mobile: 6.25rem
    )
);

@function iconSize($size, $platform: 'desktop') {
    @return map-get(map_get($iconSizes, $size), $platform);
}

$spacings: (
    tn: 4px,
    xs: 6px,
    sm: 8px,
    base: 16px,
    md: 32px,
    lg: 48px,
    xl: 72px,
    hg: 96px
);

@function spacing($size: 'base')
{
    @return map-get($spacings, $size);
}

$directions: (
    top, left, right, bottom
);

$breakpoints: (
    xs: 1px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hg: 1560px
);

:root {
    @each $breakpoint-key, $breakpoint in $breakpoints {
        --breakpoint-#{$breakpoint-key}: #{$breakpoint};
    }
}

$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;

$site-frame-width-base: 80px;
$site-frame-width-small: 56px;
$site-frame-height-base: 100%;
$bottom-frame-height-base: 576px;
$bottom-frame-height-mobile: auto;

$border-width-thin: 1px;
$border-width-base: 2px;
$border-width-fat: 2px;
$border-width-heavy: 3px;
$border-width-huge: 6px;
$border-radius-base: 8px;
$border-radius-large: 8px;
$border-radius-huge: 16px;
$border-color-base: color(black);

$box-shadow-base: 0 1px 2px 0 rgba(color(black), .15);
$box-shadow-large: 0 4px 8px 0 rgba(color(black), .2);
$box-shadow-heavy: 10px 15px 60px rgba(color(black), .1);

$transition-base: 0.4s all ease-in-out;
$transition-fast: 0.2s all ease-in-out;

$logo-width: 31px;
$logo-height: 41px;
$logo-width-small: 26px;
$logo-height-small: 36px;

:root {
    --app-height: 100vh;
    --frame-width: #{$site-frame-width-base};
    --bottom-frame-height: #{$bottom-frame-height-base};
    --logo-width: #{$logo-width};
    --logo-height: #{$logo-height};
    --base-transition-timing: 400ms;
    --border-width-base: #{$border-width-base};
    --border-width-thin: #{$border-width-thin};
}

@media only screen and (max-width: $screen-sm)
{
    :root {
        --frame-width: #{$site-frame-width-small};
        --logo-width: #{$logo-width-small};
        --logo-height: #{$logo-height-small};
    }
}
