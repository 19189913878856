.about
{
    &__zolly {
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        opacity: .1;
        mix-blend-mode: color-burn;
        transition: all 300ms ease-out;
        pointer-events: none;
        user-select: none;

        &-text {
            transition: all 300ms ease-in;

            &:hover {
                color: var(--secondary-color);

                & + .about__zolly {
                    mix-blend-mode: unset;
                    opacity: .8;

                    img {
                        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(54,54,56,1)), to(rgba(54,54,56,0)))
                    }
                }
            }
        }

        &:before {
            //content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            //background: var(--background-color);
            background: linear-gradient(0deg, rgba(54,54,56,1) 0%, rgba(54,54,56,0) 100%);
        }
    }

    .section
    {
        background-color: inherit;
    }

    &__welcome
    {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-huge;
        font-weight: $font-weight-bold;

        &-content {
        }

        &-text {
            display: flex;
            flex-wrap: wrap;

            span {
                display: inherit;
            }
        }
    }

    &__copy
    {
        font-size: $font-size-medium;
        line-height: $line-height-base;
    }

    &__clients
    {
        display: flex;
        flex-wrap: wrap;

        &-item
        {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-basis: 25%;
            padding: spacing(base) 0;

            .icon {
                stroke: none;
                fill: currentColor;
            }
        }
    }

    &__footer {
        display: flex;
        width: 100%;
    }

    &__social-links {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: spacing(base);
        width: 100%;
        height: 200px;
        background: color(grey, 900);
        padding: spacing(md);
        font-weight: $font-weight-bold;
        white-space: nowrap;
        text-align: center;
        overflow: hidden;

        &--linkedin {
            background: color(brand, linkedin);
        }

        &--cv {
            background: var(--secondary-color);
        }

        &--spotify {
            background: color(brand, spotify);
        }

        .icon {
            animation-direction: alternate;
        }

        &:hover {
            .icon {
                animation: animIcon .6s ease-in-out;
            }
        }

        &--blog {
            position: relative;
            background-color: color(grey, 900);
            text-transform: uppercase;
            font-weight: $font-weight-black;
            font-size: $font-size-large;
            transition: all 500ms ease-in-out;
            line-height: .69em;

            //&:before {
            //    @extend .navigation__item--blog:before;
            //    z-index: -1;
            //}
            //
            //&:after {
            //    @extend .navigation__link--blog:after;
            //    text-transform: none;
            //    margin-bottom: -28px;
            //    color: color(grey, 500);
            //}
            //
            //&:hover {
            //    background-color: transparent;
            //
            //    &:after {
            //        color: color(white);
            //    }
            //}
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .about {
        &__welcome {
            font-size: $font-size-extra-large;
            margin-bottom: 0;

            &-content {
                flex-direction: column-reverse;
                margin: 0;
            }
        }

        &__clients {
            &-item {
                width: 50%;
                flex-basis: 50%;
            }
        }

        &__zolly {
            position: fixed;
            mix-blend-mode: soft-light;
            opacity: .8;
        }

        &__footer {

        }

        &__social-links {
            flex-direction: column;
        }
    }
}

@-webkit-keyframes animIcon {
    0% {
        top: 0;
    }
    40% {
        top: 100%;
    }
    41% {
        top: -1000%;
        filter: blur(2px);
    }
    50% {
        top: -100%;
        filter: blur(2px);
    }
    90% {
        top: 0;
    }
    100% {
        top: 0;
    }
}
