$slide-content-width: 80vw;
$slide-content-height: 80vh;

html {
    &[page="projects"] {
        overflow-y: auto;
        overscroll-behavior-y: contain;
        scroll-snap-type: y mandatory;

        &[loading="true"],
        &[overlay-visible="true"] {
            overflow-y: hidden;
            overscroll-behavior-y: none;
            scroll-snap-type: none;
        }
    }
}

.fos {
    position: relative;
}

.projects
{
    &__section {
        $section: &;

        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        scroll-snap-align: center;
        perspective: 500px;
        user-select: none;
        overflow: hidden;

        &--wrapless {
            --color-accent-primary: #{color(brand, wrapless)};
        }

        &--end-to-end {
            --color-accent-primary: #{color(brand, bt)};
        }

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            height: 100vh;
            padding-inline: var(--frame-width);
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }

        &-title {
            position: relative;
            text-align: center;
            z-index: 1;
            mix-blend-mode: screen;
        }

        &-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            pointer-events: none;
            user-select: none;
            overflow: hidden;

            li {
                display: inline-flex;
                width: auto;
                height: auto;
                position: relative;
                //transform: rotate(90deg);
            }
        }

        &-background {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1px;
            height: 1px;
            position: absolute;
            z-index: 1;
            backface-visibility: hidden;
            //width: 1px;
            //height: 400px;
            //max-height: 90vh;
            //margin: 20px;
            //background: var(--white);

            img {
                width: 740px;
                //height: 740px;
                //top: calc(50% - 370px);
                //left: calc(50% - 370px);
                height: auto;
                max-width: none;
                max-height: none;
                position: absolute;
                backface-visibility: hidden;
                animation: 1.5s cubic-bezier(.25, 1, .30, 1) circle-out-center both;
                will-change: clip-path;
            }
        }

        &-button-container {
            position: relative;
            z-index: 3;
        }

        &--active {
            &#{$section}--wrapless {
            }

            #{$section} {
                &-background {
                    img {
                        animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-in-center both;
                    }
                }
            }
        }
    }
}

html {
    &[page="projects"] {
        &[section="end-to-end"] {
            --color-accent-primary: #{color(brand, bt)};
            --color-accent-negative: #{color(white)};
        }
        &[section="wrapless"] {
            --color-accent-primary: #{color(brand, wrapless)};
            --color-accent-negative: #{color(white)};
        }
        &[section="quick pay"] {
            --color-accent-primary: #{color(brand, quickpay)};
            --color-accent-negative: #{color(white)};
        }
    }
}

@keyframes circle-in-center {
    from {
        clip-path: circle(0%);
    }
    to {
        clip-path: circle(125%);
    }
}

@keyframes circle-out-center {
    from {
        clip-path: circle(125%);
    }
    to {
        clip-path: circle(0%);
    }
}

@media only screen and (max-width: $screen-sm)
{
    html {
        .projects {
            padding-top: 0;

            &__section {
                min-height: 100vh;
                min-height: -webkit-fill-available;

                &-content {
                    min-height: 100vh;
                    min-height: -webkit-fill-available;
                    padding-inline: spacing(sm);
                }

                &-title {
                    overflow-wrap: anywhere;
                    font-size: $font-size-huge;
                }

                &-list {
                    display: none;
                }

                &-background {
                    img {
                        max-width: 150vw;
                    }
                }
            }
        }
    }
}
