.wrapless
{
    &__personas
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: spacing(xl) 0;

        &-item
        {
            &:last-child
            {
                margin-left: spacing(md) * -1;
                margin-top: spacing(md);
            }
        }
    }

    &__user-journey
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: spacing(xl) 0;
    }

    &__colors
    {
        display: flex;
        justify-content: center;
        gap: spacing(md);

        &-item
        {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin-right: spacing(md);
            white-space: nowrap;

            ul
            {
                li
                {
                    span
                    {
                        display: inline-flex;
                        width: 25px;
                    }
                }
            }

            &:last-child
            {
                margin-right: 0;
            }

            &--half
            {
                width: 100px;
            }

            &--quarter
            {
                width: 50px;
            }
        }

        &-box
        {
            width: 100%;
            height: 200px;
            margin-bottom: spacing(md);

            &--red
            {
                background-color: #E84C3D;
            }

            &--dark-blue
            {
                background-color: #34495E;
            }

            &--green
            {
                background-color: #27B899;
            }

            &--light-blue
            {
                background-color: #43A1E2;
            }

            &--yellow
            {
                background-color: #FABE3A;
            }
        }
    }


    &__surveys {
        &-image {
            max-width: none;
        }
    }

    &__logo-section
    {
        &-graphics
        {
            height: 400px;
        }

        &-bg
        {
            width: 100%;
        }

        &-box
        {
            &-container
            {
                position: absolute !important;
                right: 0;
                top: -10%;
                z-index: 1;
            }
        }

        &-logo
        {
            max-width: 450px;
            margin: spacing(hg) auto;
        }
    }

    &__ui-design
    {
        &--how-it-works-blocks
        {
            max-width: 380px;
        }

        &--product-item
        {
            max-width: 260px;
        }

        &--gift-list-overlay
        {
            max-width: 580px;
        }

        &--screens-grid
        {
            &-background
            {
                height: 1024px;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: cover;
                background-image: url(#{$images-folder}wrapless/ui-screens-grid.png);

                @media (-webkit-min-device-pixel-ratio: 2),
                (min-resolution: 192dpi)
                {
                    background-image: url(#{$images-folder}wrapless/ui-screens-grid@2x.png);
                }
            }
        }

        &--notebook-neg-offset
        {
            margin-bottom: -400px;
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .wrapless
    {
        &__hero
        {
            background-position: 30px top;
            background-size: 100%;
        }

        &__surveys
        {
            &-item,
            &-image
            {
                margin-bottom: spacing(base);
            }

            &-item
            {
                flex-direction: column;

                .section__text-block
                {
                    text-align: center;
                }
            }
        }

        &__personas
        {
            padding-top: spacing(md);
            flex-direction: column;

            &-item
            {
                margin: 0 auto spacing(base) auto;

                &:last-child
                {
                    margin: 0 auto;
                }
            }
        }

        &__logo-section
        {
            &-box
            {
                max-width: 500px;
            }
        }

        &__ui-design
        {
            &--notebook-neg-offset
            {
                margin-bottom: -200px;
            }

            &--screens-grid
            {
                padding-left: 0;
                padding-right: 0;

                &-background
                {
                    height: 100vh;
                }
            }
        }

        &__colors
        {
            &-item
            {
                width: 150px;
                margin: 0;

                &--half
                {
                    width: 100px;
                }

                &--quarter
                {
                    width: 75px;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .wrapless
    {
        &__hero
        {
            background-position: -50px top;
            background-size: 150%;
        }

        &__user-journey
        {
            padding-bottom: spacing(base);
        }

        &__personas
        {
            &-item
            {
                &:last-child {
                    margin-top: spacing(md);
                }
            }
        }

        &__logo-section
        {
            &-graphics
            {
                height: 200px;
            }

            &-bg
            {
                width: 250%;
                max-width: none;
            }

            &-box
            {
                max-width: 35vh;
            }

            &-logo
            {
                max-width: 100%;
            }
        }

        &__ui-design
        {
            &--notebook
            {
                &-neg-offset
                {
                    margin-bottom: -75px;
                }

                &-container
                {
                    width: 150%;
                    position: relative;
                    left: -29%;
                }
            }

            &--screens-grid
            {
                &-background
                {
                    height: 65vh;
                }
            }
        }

        &__colors
        {
            flex-wrap: wrap;

            &-item
            {
                width: 50%;
                margin-bottom: spacing(md);

                &--half
                {
                    width: 33%;
                }

                &--quarter
                {
                    width: 33%;
                }
            }
        }
    }
}
