.images {
    display: grid;
    width: 100%;
    gap: spacing(md);
    grid-template-columns: auto auto;

    &__column {
    }

    &__item {
        max-width: 100%;
    }
}

@media only screen and (max-width: $screen-sm) {
    .images {
        grid-template-columns: 1fr;
    }
}
