body {
    .carousel {
        isolation: isolate;

        &__content {
            position: relative;
            z-index: 11;
        }

        &.swiper {
            width: 100%;
            height: auto;

            --swiper-theme-color: #{color(grey, 900)};

            .swiper {
                &-slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: spacing(md) spacing(lg);
                    background-color: color(white) !important;
                    border: $border-width-base solid var(--primary-color);
                    transition: transform 300ms, -webkit-transform 300ms, padding 300ms !important;
                    user-select: none;
                    overflow: visible;

                    &.testimonial {
                        &:before,
                        &:after {
                            opacity: 0;
                        }
                    }

                    &-visible {
                        padding: spacing(lg);

                        &.testimonial {
                            &:before,
                            &:after {
                                opacity: 1;
                            }
                        }
                    }

                    &-shadow {
                        background-color: color(grey, 100) !important;
                    }
                }

                &-pagination {
                    --swiper-pagination-bullet-size: 16px;
                    --swiper-pagination-bottom: -100px;
                    --swiper-pagination-bullet-inactive-color: transparent;
                    --swiper-pagination-bullet-inactive-opacity: 1;

                    &-bullet {
                        border: $border-width-base solid color(black);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    body {
        .carousel.swiper {
            .swiper {
                &-pagination {
                    --swiper-pagination-bottom: -40px;
                }
            }
        }
    }
}
