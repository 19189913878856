.video-block {
    @extend .image-block;
    $root: &;

    &--align {
        &-center {
            @extend .image-block--align-center;
        }

        &-right {
            @extend .image-block--align-right;
        }
    }

    &__replay-button {
        $replay: &;
        @extend .caption;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        color: var(--color-accent-primary);

        &-content {
            @extend .button;
            gap: spacing(sm);
            padding: spacing(sm);
            backdrop-filter: blur(5px);
            background-color: rgba(color(white), 0.4);
            color: var(--color-accent-primary);
            border: $border-width-base solid currentColor;
            overflow: hidden;
            opacity: 0;
            transition: 150ms opacity ease-in-out;
        }

        &.visible {
            pointer-events: auto;

            #{$replay}-content {
                opacity: 1;
            }

            #{$replay}-icon,
            #{$replay}-text {
                transform: translateX(0);
                opacity: 1;
            }
        }

        &-icon,
        &-text {
            opacity: 0;
            backface-visibility: hidden;
            transition: all 400ms ease-in-out;
            color: currentColor;
        }

        &-icon {
            transform: translateX(-20px);
        }

        &-text {
            padding-top: $border-width-base;
            transform: translateX(20px);

        }
    }
}
