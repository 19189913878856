.main
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    background-color: var(--background-color);
    color: var(--primary-color);
}

@media only screen and (max-width: $screen-md)
{
    .main
    {
    }
}
