.text-block {
    $root: &;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: container(1000);
    margin-bottom: spacing(md);

    .container {
        margin-inline: 0;
    }

    &__heading {
        position: relative;
        margin-bottom: spacing(base);
    }

    &__caption {
        color: var(--color-accent-primary);
        margin-bottom: spacing(sm);
    }

    &__text {
        margin-bottom: 0;
    }

    &.align {
        &--center {
            justify-content: center;

            .container {
                margin-inline: auto;
            }

            #{$root}__heading {
                &--shifted {
                    right: auto;
                }
            }
        }

        &--right {
            justify-content: flex-end;

            > .container {
                margin-inline-start: auto;
            }

            #{$root}__heading {
                &--shifted {
                    left: auto;
                    right: spacing(md) * -1;
                }
            }
        }
    }

    &--shifted {
        padding-inline: spacing(md) 0;

        #{$root}__heading {
            left: spacing(md) * -1;
        }

        &.align--right {
            padding-inline: 0 spacing(md);

            #{$root}__heading {
                right: spacing(md) * -1;
                left: auto;
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .text-block {
        max-width: 100%;

        &__text {
        }
    }
}
