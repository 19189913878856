*
{
    box-sizing: border-box;

    &:focus
    {
        outline: 0;
    }

    &:active
    {
        outline: 0;
    }

    &:before,
    &:after,
    &::before,
    &::after
    {
        box-sizing: border-box;
    }
}
