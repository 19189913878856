.section
{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: spacing(hg) 0;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @each $color-key, $color-value in $colors
    {
        @each $tone-key, $tone-value in $color-value
        {
            @if $tone-key == base
            {
                &-bg--#{$color-key}
                {
                    background-color: $tone-value !important;
                }
            } @else
            {
                &-bg--#{$tone-key}-#{$color-key}
                {
                    background-color: $tone-value !important;
                }
            }
        }
    }

    &-bg--black
    {
        color: color(white);

        .heading
        {
            &--4
            {
                color: color(white);
            }
        }
    }

    &__content
    {
        margin: 0 auto;
        position: relative;
    }

    &--framed
    {
        padding-inline: var(--frame-width);
        margin: 0 auto;
    }

    &__container
    {
        width: 100%;
        position: relative;
        padding: 0 spacing(md);

        &--narrow
        {
            max-width: 1000px;
            margin: 0 auto;
        }

        &--narrower
        {
            max-width: 700px;
            margin: 0 auto;
        }

        &--framed
        {
            padding: 0 var(--frame-width);
            margin: 0 auto;
        }

        &--flex
        {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &__row
    {
        display: flex;

        &--left
        {
            margin-right: auto;
        }

        &--right
        {
            margin-left: auto;
        }

        &--narrow
        {
            max-width: 760px;
        }

        &--thick
        {
            max-width: 650px;
        }

        &--narrower
        {
            max-width: 600px;
        }
    }

    &--gray
    {
        &-lightest
        {
            background-color: color(grey, lightest);

            &-grad
            {
                &--in
                {
                    background: linear-gradient(to bottom, color(white) 0%, color(grey, lightest) 100%);
                }

                &--out
                {
                    background: linear-gradient(to bottom, color(grey, lightest) 0%, color(white) 100%);
                }
            }
        }
    }

    &--full
    {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &--overflow
    {
        overflow: visible;
    }
}

@media only screen and (max-width: $screen-md)
{
    .section
    {
        padding-inline: var(--frame-width);

        &__text-block
        {
            .heading
            {
                &--shifted
                {
                    left: auto !important;
                    right: auto !important;
                }
            }
        }

        &__container
        {
            padding: 0;

            &--framed
            {
                padding: 0;
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .section {
        padding: spacing(md);
    }
}
