.project
{
    margin-bottom: var(--bottom-frame-height);
}

@media only screen and (max-width: $screen-sm)
{
    .project {
        padding-top: 0;
        margin-bottom: 0;
    }
}
