.next-project
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: var(--bottom-frame-height);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-color: color(grey, 900);
    color: color(white);
    padding-bottom: 0;

    &__title {
        @extend .heading;
        @extend .heading--2;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 0;
        line-height: normal;
        vertical-align: middle;
        position: relative;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
            .button--split {
                @extend .button--split:hover;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(lg);
        width: 100%;
        border-top: $border-width-base solid color(grey, 800);
        padding-block: spacing(md);
        margin-top: auto;
    }
}

@media only screen and (max-width: $screen-sm) {
    .next-project {
        position: static;
        height: auto;
        padding-block: spacing(lg);

        &__title {
            margin-bottom: spacing(sm);
        }

        &__footer {
            flex-direction: column;
            margin-top: spacing(lg);
            gap: spacing(base);
        }
    }
}
