$header-height: 50px;
$header-height-sm: 50px;
$header-bg: color(grey, white);

.header
{
    $root: &;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: $header-height;
    height: $header-height;
    background-color: $header-bg;
    transition: $transition-base;
    //box-shadow: 0 4px 20px 0 rgba($color-secondary, .1);
    position: relative;

    &__title
    {
        width: 100%;
        position: absolute;
        text-align: center;
        user-select: none;
        pointer-events: none;
    }

    &__info
    {
        display: flex;
        align-items: center;
        justify-content: center;

        &-group
        {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 spacing(sm);
            border-right: $border-width-base solid $border-color-base;

            &:last-child
            {
                border-right: none;
            }
        }

        .datalist
        {
            height: 100%;
            margin: 0;
            padding: 0;
            line-height: $line-height-small;
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .header
    {
        height: $header-height-sm;
    }
}

@media only screen and (max-width: $screen-sm)
{
    .header
    {
        height: $header-height-sm;
    }
}

@media only screen and (min-width: $screen-lg)
{
    .header
    {
    }
}
