$form-label-font-size-base: $font-size-base;
$form-label-font-weight-base: $font-weight-demi;

.form {
    &__label {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: $form-label-font-size-base;
        font-weight: $form-label-font-weight-base;
        margin-bottom: spacing(xs);

        &--nowrap {
            white-space: nowrap;
        }

        &--auto {
            width: auto;
        }

        &--bold {
            font-weight: $font-weight-bold;
        }
    }
}
