.grid
{
    $root: &;

    display: flex;
    flex-direction: column;
    width: 100%;

    &__row
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: spacing(base);

        &--column {
            flex-direction: column;
            flex-wrap: wrap;
        }

        &--align-center
        {
            align-items: center;
        }

        &--to-center
        {
            justify-content: center !important;
        }

        &--align-left
        {
            justify-content: flex-start !important;
        }

        &--align-bottom
        {
            align-items: flex-end;
        }

        &--auto
        {
            & #{$root}__column
            {
                flex: auto;
                width: auto;
            }
        }

        &--auto-height
        {
            height: auto !important;
        }

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    &__column
    {
        display: flex;
        flex-basis: 100%;
        max-width: 100%;
        flex-shrink: 1;

        &--long
        {
            flex-grow: 2;
        }

        &--short
        {
            flex-grow: .75;
        }

        &--full
        {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }

        &--auto
        {
            flex-basis: auto !important;
            max-width: auto !important;
        }

        &--border
        {
            &--left
            {
                border-left: $border-width-base solid $border-color-base;
                padding-left: spacing(sm) !important;
            }
        }

        &--rows
        {
            flex-direction: column;
        }
    }

    &__column-content
    {
        width: 100%;
    }

    &--2-columns
    {
        & #{$root}__row
        {
            justify-content: space-between;
        }

        & #{$root}__column
        {
            flex-basis: calc(50% - #{spacing(base)});
            max-width: calc(50% - #{spacing(base)});

            &--fix
            {
                flex-basis: 50%;
                max-width: 50%;
            }

            &--sm-spacing
            {
                flex-basis: calc(50% - 14px);
                max-width: calc(50% - 14px);
            }
        }
    }

    &--3-columns
    {
        & #{$root}__row
        {
            justify-content: space-between;
        }

        & #{$root}__column
        {
            flex-basis: calc(33.333% - #{spacing(base)});
            max-width: calc(33.333% - #{spacing(base)});

            &--fix
            {
                flex-basis: 33.333%;
                max-width: 33.333%;
            }

            &--long
            {
                flex-basis: calc(66.666% - #{spacing(base)});
                max-width: calc(66.666% - #{spacing(base)});
                padding-left: 30px;
            }

            &--sm-spacing
            {
                flex-basis: calc(33.333% - 14px);
                max-width: calc(33.333% - 14px);
            }
        }
    }

    &--4-columns
    {
        & #{$root}__row
        {
            justify-content: space-between;
        }

        & #{$root}__column
        {
            flex-basis: calc(25% - #{spacing(base)});
            max-width: calc(25% - #{spacing(base)});

            &--fix
            {
                flex-basis: 25%;
                max-width: 25%;
            }

            &--sm-spacing
            {
                flex-basis: calc(25% - 14px);
                max-width: calc(25% - 14px);
            }
        }
    }

    &--row
    {
        flex-direction: row;
    }
}

.box
{
    padding: spacing(md);
    border: $border-width-base solid color(grey, 700);
}

@media only screen and (max-width: $screen-md)
{
    .grid
    {
        $root: &;

        &__row
        {
            flex-direction: column;

            &--no-margin
            {
                &--md
                {
                    margin-bottom: 0 !important;
                }
            }
        }

        &__column
        {
            flex-basis: 100% !important;
            width: 100% !important;
            max-width: 100% !important;
            margin-bottom: spacing(base);
        }

        &--md
        {
            &--2-columns
            {
                & #{$root}__row
                {
                    flex-direction: row;
                }

                & #{$root}__column
                {
                    flex-basis: calc(50% - #{spacing(base)}) !important;
                    max-width: calc(50% - #{spacing(base)}) !important;
                    width: calc(50% - #{spacing(base)}) !important;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .grid
    {
        &__row
        {
            &--no-margin
            {
                &--sm
                {
                    margin-bottom: 0 !important;
                }
            }

            &--reverse
            {
                &--sm
                {
                    flex-direction: column-reverse;
                }
            }
        }

        &--border
        {
            &--left
            {
                border-left: none;
            }
        }
    }
}
