$checkbox-size: 20px;
$checkbox-icon-size: 12px;

$checkbox-border-width: $border-width-base;
$checkbox-border-radius: 0;

$checkbox-border-color-base: $border-color-base;
$checkbox-color-base: color(white);
$checkbox-bg-color-base: color(white);

$checkbox-border-color-checked: color(orange);
$checkbox-color-checked: color(white);
$checkbox-bg-color-checked: color(orange);

$checkbox-disabled-opacity: $form-disabled-opacity;

.checkbox {
    $checkbox: &;

    display: flex;
    align-items: center;

    &__label {
        @extend .form__label;

        display: flex;
        align-items: center;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: $font-weight-demi;
        font-size: $font-size-small;
        line-height: $line-height-base;

        &--md {
            font-size: $font-size-base;
            line-height: $line-height-extra-small;
        }

        &--sm {
            font-size: $font-size-extra-small;
            font-weight: $font-weight-regular;
        }
    }

    &__tick {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $checkbox-size;
        min-width: $checkbox-size;
        height: $checkbox-size;
        min-height: $checkbox-size;
        line-height: $checkbox-size - ($checkbox-border-width * 4);
        background-color: $checkbox-bg-color-base;
        vertical-align: bottom;
        border-radius: $checkbox-border-radius;
        border: $checkbox-border-width solid $checkbox-border-color-base;
        text-align: center;
        color: $checkbox-color-base;
        margin-right: spacing(xs);
        position: relative;

        .icon {
            width: $checkbox-icon-size;
            min-width: $checkbox-icon-size;
            height: $checkbox-icon-size;
            min-height: $checkbox-icon-size;
            display: none;
            margin: 0;
            stroke-width: $border-width-base;
            stroke: $checkbox-color-base;
        }
    }

    &__input,
    input {
        display: inline-block;
        vertical-align: middle;
        width: auto;

        &:checked:not(&:disabled) {
            & + #{$checkbox}__label {
                & #{$checkbox}__tick {
                    background-color: $checkbox-bg-color-checked;
                    border-color: $checkbox-border-color-checked;
                    color: $checkbox-color-checked;

                    .icon {
                        display: inline-block;
                    }
                }
            }
        }
    }

    &--full {
        width: 100%;
    }

    &--disabled,
    &__input:disabled {
        opacity: $checkbox-disabled-opacity;

        #{$checkbox} {
            &__label {
                cursor: not-allowed;
            }

            &__tick {
                background-color: $form-input-bg-color-disabled;
                border-color: $form-input-border-color-disabled;
            }
        }
    }
}
