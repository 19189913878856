.notification
{
    padding: spacing(xs) spacing(base);
    border-bottom: $border-width-base solid $border-color-base;

    &:last-child
    {
        border-bottom: none;
    }

    &__date
    {
        font-size: $font-size-tiny;
    }

    &__message
    {
        margin: 0;
        font-size: $font-size-base;
        color: $font-color-base;
    }

    &__data
    {
        font-size: $font-size-tiny;
        color: $font-color-base;
    }

    &__footer
    {
        display: flex;
        align-items: center;
    }

    &--highlighted
    {
        //background-color: rgba($color-primary, .05);
    }
}
