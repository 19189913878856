.heading
{
    font-weight: $font-weight-bold;
    margin-bottom: spacing(base);
    line-height: $line-height-base;
    text-transform: none;

    &--center
    {
        text-align: center;
    }

    &--flex
    {
        display: flex;
        align-items: center;
    }

    &--1
    {
        font-size: $font-size-giant;
        font-weight: $font-weight-black;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: -0.12rem;
    }

    &--2
    {
        font-size: $font-size-huge;
        font-weight: $font-weight-black;
        line-height: $line-height-extra-small;
        letter-spacing: -0.05rem;
        margin-bottom: spacing(md);
    }

    &--3
    {
        font-size: $font-size-large;
        letter-spacing: normal;
    }

    &--4
    {
        font-size: $font-size-small;
        color: var(--secondary-color);
    }

    &--5
    {
        font-size: $font-size-small;
    }

    &--6
    {
        font-size: $font-size-small;
    }

    &__link
    {
        font-size: .55em;
        font-weight: $font-weight-regular;
    }

    &--sm-margin
    {
        margin-bottom: spacing(sm);
    }

    &--xs-margin
    {
        margin-bottom: spacing(xs);
    }

    &--circled
    {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-width: 70px;
        min-height: 70px;
        padding: spacing(base);
        border: $border-width-base solid currentColor;
        border-radius: 50%;
        mix-blend-mode: difference;
    }

    &__sub {
        opacity: .5;
        font-size: .75em;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: $screen-sm)
{
    .heading
    {
        //line-height: $line-height-base;
        //letter-spacing: 1;

        &--1
        {
            font-size: $font-size-large;
            line-height: $line-height-base;
        }

        &--2
        {
            font-size: $font-size-extra-large;
            //line-height: $line-height-base;
            //margin-bottom: spacing(md);
        }

        &--3
        {
            font-size: $font-size-medium;
        }

        &--flex
        {
            flex-direction: column;
            align-items: flex-start;
        }

        &--center-sm
        {
            text-align: center;
        }
    }
}
