.draw {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
    pointer-events: none;

    &--absolute {
        position: absolute;
    }
}
