.navigation
{
    $navigation: &;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: spacing(tn);
    padding-inline: var(--frame-width);
    position: relative;
    z-index: 1;

    &__blog-section {
        > *:first-child {
            padding-top: spacing(md);
            margin-top: spacing(base);
            border-top: $border-width-thin solid var(--secondary-color);
        }
    }

    &__item
    {
        position: relative;

        &--blog {
            margin-block: spacing(sm) 0;
            border-radius: 12px;
            overflow: hidden;
            transition: all 400ms ease-in-out;
            width: 456px;
            max-width: 100%;
            border: $border-width-base solid transparent;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .5;
                transition: all 400ms ease-in-out;
                background-image: url(#{$images-folder}blog/blog-1.png);
                background-color: var(--secondary-color);
                background-size: cover;
                background-position: center 10%;
                z-index: 0;
                transform-origin: center center;
                transform: scale(1);
                background-blend-mode: multiply;
                animation: animBlogBg 3s infinite alternate;
            }

            &:hover {
                border: $border-width-base solid var(--color-accent-negative);

                #{$navigation} {
                    &__link {
                        &--blog {
                            -webkit-text-stroke: $border-width-thin color(white);
                        }

                        #{$navigation} {
                            &__link-sub-title {
                                color: color(white);
                            }
                        }
                    }
                }
            }

            &:after {
                content: "";
                display: block;
                background-image: url(#{$images-folder}blog/blog-logo-en-sm@2x.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 164px;
                height: 100%;
                position: absolute;
                top: 0;
                right: spacing(md);
            }

            &:has(#{$navigation}__link.active) {
                border-color: var(--secondary-color-negative);
                &:before {
                    transform: scale(1);
                    background-position: center 58%;
                    background-color: var(--secondary-color);
                    opacity: .5;
                    animation: animBlogBg 3s infinite;
                    animation-direction: alternate;
                }

                #{$navigation} {
                    &__link {
                        color: color(white);
                        -webkit-text-stroke: initial;

                        &:before {
                            color: color(white);
                        }
                        &:before {
                            opacity: .25;
                            top: -100%;
                        }
                    }
                }

            }
        }

        &--cliche {
            &:before {
                background-image: url(#{$images-folder}cliche-blog/3.jpg);
                animation: animClicheBg 3s infinite alternate;
            }

            &:after {
                display: none;
            }

            &:has(#{$navigation}__link.active) {
                &:before {
                    animation: animClicheBg 3s infinite;
                }
            }
        }
    }

    &__image
    {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    &__link
    {
        font-size: $font-size-extra-large;
        font-weight: $font-weight-black;
        line-height: $font-size-extra-large;
        text-transform: uppercase;
        -webkit-text-stroke: $border-width-base var(--secondary-color);
        color: transparent;
        position: relative;
        transition: -webkit-text-stroke 300ms ease-in-out;

        &-sub-title {
            display: block;
            text-transform: none;
            font-weight: $font-weight-demi;
            font-size: $font-size-tiny;
            line-height: 1.25em;
            -webkit-text-stroke: initial;
            color: var(--secondary-color-negative);
            margin-bottom: 0;
            transition: color 300ms ease-in-out;
        }

        &.active
        {
            -webkit-text-stroke: initial;
            color: color(white);
            -webkit-text-fill-color: currentColor;

            &:hover {
                -webkit-text-stroke: initial;
            }

            #{$navigation} {
                &__link {
                    &-sub-title {
                        color: inherit;
                    }
                }
            }
        }

        &--blog {
            display: flex;
            flex-direction: column;
            gap: 0;
            font-size: 48px;
            line-height: 1;
            padding: spacing(md);
            color: transparent;
            -webkit-text-stroke: $border-width-thin var(--secondary-color-negative);
            transition: all 250ms ease-in-out;
            position: relative;
            top: 2px;
            z-index: 1;
        }

        &--cliche {
            font-size: $font-size-large;
            line-height: $line-height-base;
        }

        &:hover
        {
            -webkit-text-stroke: $border-width-base color(white);

            #{$navigation} {
                &__link-sub-title {
                    color: color(white);
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .navigation {
        gap: 0;

        &__item {
            padding-inline: spacing(md);

            &--blog {
                padding-inline: spacing(md);
            }
        }

        &__link {
            font-size: $font-size-medium-large;

            &--blog {
                width: 100%;
                font-size: $font-size-large;
                padding-inline: 0;
            }
        }
    }
}

@keyframes animClicheBg {
    0% {
        background-image: url(#{$images-folder}cliche-blog/3.jpg);
        background-position: center 58%;
        filter: blur(2px);
    }
    10% {
        background-image: url(#{$images-folder}cliche-blog/3.jpg);
        background-position: center 52%;
        filter: blur(0);
    }
    25% {
        background-image: url(#{$images-folder}cliche-blog/3.jpg);
        background-position: center 51%;
    }
    75% {
        background-image: url(#{$images-folder}cliche-blog/4.jpg);
        background-position: center -1150px;
    }
    100% {
        background-image: url(#{$images-folder}cliche-blog/4.jpg);
        background-position: center -1100px;
    }
}

@keyframes animBlogBg {
    0% {
        background-image: url(#{$images-folder}blog/blog-1.png);
        background-position: center 20%;
        filter: blur(2px);
    }
    15% {
        background-image: url(#{$images-folder}blog/blog-1.png);
        background-position: center 10%;
        filter: blur(0);
    }
    39% {
        background-image: url(#{$images-folder}blog/blog-3.png);
        background-position: center 72%;
    }
    67% {
        background-image: url(#{$images-folder}blog/blog-3.png);
        background-position: center 30%;
    }
    100% {
        background-image: url(#{$images-folder}blog/blog-3.png);
        background-position: center 20%;
    }
}
