.list
{
    &--inline
    {
        li
        {
            display: inline-block;
        }
    }

    &--disc
    {
        list-style-position: outside;
        padding-left: .5rem;

        li
        {
            display: flex;
            flex-direction: row;

            &::before {
                content: "•";
                padding-right: .75rem;
                color: currentColor;
            }
        }
    }

    &--comma
    {
        li
        {
            &:after
            {
                content: ',';
            }

            &:last-child
            {
                &:after
                {
                    display: none;
                }
            }
        }
    }
}
