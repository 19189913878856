.datalist
{
    margin: spacing(tn) 0;
    line-height: $line-height-medium;
    font-size: $font-size-small;

    &__title,
    &__description
    {
        font-size: inherit;
    }

    &__title
    {
        margin-bottom: 0;
    }

    &__description
    {
        font-weight: $font-weight-medium;
        margin-bottom: spacing(tn);

        &:last-child
        {
            margin-bottom: 0;
        }
    }
}
