.page-transition-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: var(--app-height);
    background: color(grey, 900);
    opacity: 1;
    transform-origin: bottom;
    backface-visibility: hidden;

    &--out {
        transform-origin: top;
    }
}

body {
    .page-loader {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: spacing(md);
        justify-content: center;
        color: color(white);
        text-align: center;
        position: relative;
        opacity: 1;
        transition: opacity 300ms ease-in-out;

        &__loader {
            z-index: 1;

            span {
            }
        }

        &--hidden {
            opacity: 0;
        }

        &__logo {
            width: 52px;
            height: 52px;
            position: absolute;
            z-index: 2;
            transform: translateX(2px) translateY(-2px);
        }

        &__text {
            opacity: 0;
            position: absolute;
            top: 104%;
            font-size: $font-size-small;
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .page-transition-overlay {
    }
}
