.chapters-menu {
    $chapters: &;

    @extend .site-frame;
    @extend .site-frame--right;

    &__item {
        position: absolute;
        opacity: 0;
        margin-top: calc(-1 * var(--frame-width));
        margin-left: 190px;
        transition: all 500ms;
        pointer-events: none;
        user-select: none;

        &.active {
            margin-top: 0;
            opacity: 1;

            & ~ #{$chapters}__title {
                opacity: 1;
                margin-top: 2px;
            }
        }
    }

    &__content {
        @extend .site-frame__text;
        transition: all 600ms, background-color 0ms;
        flex-direction: revert;
    }

    &__title {
        display: block;
        padding: spacing(base) spacing(sm);
        font-weight: $font-weight-bold;
        font-size: $font-size-tiny;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-medium;
        position: absolute;
        opacity: 0;
        margin-top: calc(-1 * var(--frame-width));
        transition: all 500ms;
        cursor: pointer;
    }

    &__close {
        $close-size: 44px;

        display: flex;
        align-items: center;
        justify-content: center;
        width: $close-size;
        height: $close-size;
        position: absolute;
        right: $close-size / 2;
        top: $close-size / 2;
        color: color(white);
        opacity: 0;
        transition: opacity 300ms;
        user-select: none;
        pointer-events: none;
        cursor: pointer;

        &:before,
        &:after {
            content: ' ';
            display: block;
            position: absolute;
            height: $close-size / 2;
            width: $border-width-base;
            background-color: currentColor;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        &--visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &.chapters-menu--opened {
        transform: translateY(50px) translateX(calc(-50vw + 275px));
        width: auto;
        height: auto;
        min-height: 0;
        align-items: center;
        justify-content: center;
        padding: 0;
        mix-blend-mode: normal;
        transition: all 1000ms;

        #{$chapters} {
            &__content {
                height: auto;
                width: 550px;
                max-width: 80%;
                padding: var(--frame-width);
                transform: none;
                flex-direction: column;
                left: 0;
                gap: 10px;
                background-color: color(grey, 900);
                transition: all 600ms, background-color 1200ms;
                position: relative;
            }

            &__title {
                padding-block: 0 spacing(base);
                opacity: 1;
                margin-top: 2px;

                &.active {
                    position: relative;
                    order: -1;
                }
            }

            &__item {
                position: relative;
                opacity: 1;
                margin: 0;
                isolation: isolate;
                transition: all 300ms;
                pointer-events: auto;
                white-space: initial;
                text-align: center;
                line-height: 1.2em;

                &--next-project {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    border-top: $border-width-thin solid color(grey, 500);
                    margin-top: spacing(base);
                    padding-top: spacing(base);
                    font-weight: $font-weight-bold;

                    &:hover {
                        color: color(grey, 200);
                    }
                    &:after {
                        opacity: 0 !important;
                    }
                }

                &:after {
                    content: "";
                    display: block;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    padding: 6px 8px;
                    box-sizing: content-box;
                    position: absolute;
                    z-index: -1;
                    left: -8px;
                    bottom: -12px;
                    background-color: var(--color-accent-primary);
                    transition: all 300ms;
                }

                &.active {
                    color: var(--color-accent-negative);

                    &:after {
                        opacity: 1;
                        bottom: -6px;
                    }
                }

                &:hover:not(.active) {
                    &:after {
                        opacity: .75;
                        bottom: -6px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .chapters-menu {
        &__item {
            margin-left: 180px;
        }
        &.chapters-menu--opened {
            left: 0;
            right: 0;
            top: var(--frame-width);
            transform: none;
        }
    }
}
