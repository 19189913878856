.rolling-text {
    --rolling-text-item-gap: #{spacing(base)};
    transform: rotate(-3deg);

    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;

    &:has(img) {
        width: calc(100% + 80px);
        left: -40px;
        position: relative;
        transform: rotate(3deg);

        &.rolling-text--rev {
            margin-top: -20px !important;
            box-shadow: 10px -8px 60px rgba(color(black), .15);
            transform: rotate(-3deg);
        }
    }

    &__container {
        @extend .heading;
        @extend .heading--2;

        display: flex;
        white-space: nowrap;
        flex-wrap: nowrap;
        margin-bottom: 0;
        text-transform: uppercase;
        user-select: none;

        span {
            display: block;
            line-height: 1em;
            //margin-right: spacing(base);

            &:has(img) {
                line-height: 0;

                img {
                    display: inline-block;
                    max-width: none;
                    backface-visibility: hidden;
                }
            }
        }
    }
}
