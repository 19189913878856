html
{
    @each $spacing-key, $spacing-value in $spacings {
        @if $spacing-key == base {
            .padding {
                padding: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        padding-#{$direction-key}: $spacing-value;
                    }
                }
            }
        } @else {
            .padding-#{$spacing-key} {
                padding: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        padding-#{$direction-key}: $spacing-value;
                    }
                }
            }
        }
    }

    .padding {
        @each $direction-key in $directions {
            &-auto--#{$direction-key} {
                padding-#{$direction-key}: auto;
            }
        }
    }

    .no-padding
    {
        padding: 0 !important;

        @each $direction-key in $directions {
            &--#{$direction-key} {
                padding-#{$direction-key}: 0 !important;
            }
        }
    }

    .padding {
        &-inline {
            &--tn {
                padding-inline: spacing(tn);
            }

            &--xs {
                padding-inline: spacing(xs);
            }

            &--md {
                padding-inline: spacing(md);
            }

            &--none {
                padding-inline: 0;
            }
        }
    }

    .padding {
        &-block {
            &--tn {
                padding-block: spacing(tn);
            }

            &--xs {
                padding-block: spacing(xs);
            }

            &--md {
                padding-block: spacing(md);
            }

            &--lg {
                padding-block: spacing(lg);
            }

            &--xl {
                padding-block: spacing(xl);
            }

            &--none {
                padding-block: 0;
            }
        }
    }

    .frame-spaced {
        padding-inline: var(--frame-width);
    }
}

@media only screen and (max-width: $screen-sm)
{
    html
    {
        .frame-spaced {
            padding-inline: spacing(md);
        }

        .no-padding-sm
        {
            padding: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    padding-#{$direction-key}: 0 !important;
                }
            }

            &--inline {
                padding-inline: 0 !important;
            }

            &--block {
                padding-block: 0 !important;
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .padding-sm {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                    }
                }
            } @else {
                .padding-sm-#{$spacing-key} {
                    padding: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            padding-#{$direction-key}: $spacing-value;
                        }
                    }
                }
            }
        }
    }
}
