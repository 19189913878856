$link-color-base: var(--color-accent-primary);

.link
{
    $root: &;

    color: $link-color-base;
    //cursor: pointer;
    transition: $transition-base;
    text-decoration: none;
    font-size: $font-size-base;

    &--underline
    {
        text-decoration: underline;
        border: none;
    }

    &--small
    {
        font-size: $font-size-extra-small;
    }

    &--icon
    {
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-regular;

        .icon
        {
            //@extend .icon--extra-small;
        }

        #{$root}__text
        {
            margin: 0 spacing(tn);
        }
    }

    &--disabled
    {
        pointer-events: none;
        opacity: .5;
    }

    &--block
    {
        display: block;
    }

    &:hover
    {
        text-decoration: underline;
    }
}
