html {
    @each $spacing-key, $spacing-value in $spacings {
        @if $spacing-key == base {
            .margin {
                margin: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        margin-#{$direction-key}: $spacing-value;
                    }

                    &--#{$direction-key}--neg {
                        margin-#{$direction-key}: -$spacing-value;
                    }
                }
            }
        } @else {
            .margin-#{$spacing-key} {
                margin: $spacing-value;

                @each $direction-key in $directions {
                    &--#{$direction-key} {
                        margin-#{$direction-key}: $spacing-value;
                    }

                    &--#{$direction-key}--neg {
                        margin-#{$direction-key}: -$spacing-value;
                    }
                }
            }
        }
    }

    .margin {
        @each $direction-key in $directions {
            &-auto--#{$direction-key} {
                margin-#{$direction-key}: auto;
            }
        }
    }

    .no-margin {
        margin: 0 !important;

        @each $direction-key in $directions {
            &--#{$direction-key} {
                margin-#{$direction-key}: 0 !important;
            }
        }
    }

    .margin {
        &-inline {
            margin-inline: spacing(base);

            &--neg {
                margin-inline: -(spacing(base));
            }

            &--auto {
                margin-inline: auto;
            }

            &--md {
                margin-inline: spacing(md);

                &--neg {
                    margin-inline: -(spacing(md));
                }
            }

            &--lg {
                margin-inline: spacing(lg);

                &--neg {
                    margin-inline: -(spacing(lg));
                }
            }

            &--xl {
                margin-inline: spacing(xl);

                &--neg {
                    margin-inline: -(spacing(xl));
                }
            }

            &--none {
                margin-inline: 0;
            }
        }

        &-block {
            margin-block: spacing(base);

            &--auto {
                margin-block: auto;
            }

            &--neg {
                margin-block: -(spacing(base));
            }

            &--md {
                margin-block: spacing(md);

                &--neg {
                    margin-block: -(spacing(md));
                }
            }

            &--lg {
                margin-block: spacing(lg);

                &--neg {
                    margin-block: -(spacing(lg));
                }
            }

            &--xl {
                margin-block: spacing(xl);

                &--neg {
                    margin-block: -(spacing(xl));
                }
            }

            &--none {
                margin-block: 0;
            }
        }
    }
}

@media only screen and (max-width: $screen-md) {
    html {
        .no-margin-md {
            margin: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: 0 !important;
                }
            }
        }

        .margin-auto-md {
            margin: auto !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: auto !important;
                }
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .margin-md {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            } @else {
                .margin-md-#{$spacing-key} {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    html {
        .no-margin-sm {
            margin: 0 !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: 0 !important;
                }
            }

            &--inline {
                margin-inline: 0 !important;
            }

            &--block {
                margin-block: 0 !important;
            }
        }

        .margin-auto-sm {
            margin: auto !important;

            @each $direction-key in $directions {
                &--#{$direction-key} {
                    margin-#{$direction-key}: auto !important;
                }
            }
        }

        @each $spacing-key, $spacing-value in $spacings {
            @if $spacing-key == base {
                .margin-sm {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            } @else {
                .margin-sm-#{$spacing-key} {
                    margin: $spacing-value;

                    @each $direction-key in $directions {
                        &--#{$direction-key} {
                            margin-#{$direction-key}: $spacing-value;
                        }
                        &--#{$direction-key}--neg {
                            margin-#{$direction-key}: -$spacing-value;
                        }
                    }
                }
            }
        }
    }
}
