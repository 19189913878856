/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 *
 */

// Function for converting a px based font-size to rem.
@function calculateRem($base, $size)
{
    $remSize: $size / $base;
    //Default font size on html element is 100%, equivalent to 16px;
    @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size)
{
    font-size: $size;
    font-size: calculateRem($size);
}
