.overlay
{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: var(--frame-width) 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    backface-visibility: hidden;
    pointer-events: auto;
    overflow: auto;

    &__background
    {
        display: block;
        width: 100%;
        height: 100%;
        background: color(black);
        opacity: 1;
        position: fixed;
        top: 0;
        right: 0;
        z-index: -1;
    }

    &__project-background
    {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        opacity: .4;
    }

    &.hidden
    {
        display: none;
    }

    &__close
    {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: spacing(base);
        top: spacing(base);
        font-size: $font-size-small;

        .icon
        {
            margin-left: spacing(xs);
        }
    }

    .navigation {
        max-width: 100%;
        margin: auto;
        padding: 0;
    }
}

html {
    &[overlay-visible="true"] {

    }
}

@media only screen and (max-width: $screen-sm)
{
}
