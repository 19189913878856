.stats-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: spacing(lg);

    &__item {
        text-align: center;
    }

    &__caption {
        margin-top: - spacing(base);
    }
}

@media only screen and (max-width: $screen-sm) {
    .stats-list {
        flex-direction: column;
    }
}
