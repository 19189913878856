.content
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    opacity: 1;
    //transition: opacity var(--base-transition-timing) ease-in-out;
    //will-change: opacity;

    &--block
    {
        display: block;
    }

    &--auto
    {
        width: auto;
    }

    &--padding-top
    {
        padding-top: spacing(base);
    }
}
