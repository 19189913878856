.float
{
    &--left
    {
        float: left;
    }

    &--right
    {
        float: right;
    }

    &--none
    {
        float: none !important;
    }
}
