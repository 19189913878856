// REFACTOR all the icons shit
$icons: (
    tiny: (
        desktop: 1rem,
        tablet: 1rem,
        mobile: 1rem
    ),
    extra-small: (
        desktop: 1rem,
        tablet: 1rem,
        mobile: 1rem
    ),
    small: (
        desktop: 1rem,
        tablet: 1rem,
        mobile: 1rem
    ),
    base: (
        desktop: 1rem,
        tablet: 1rem,
        mobile: 1rem
    ),
    medium: (
        desktop: 1.25rem,
        tablet: 1.25rem,
        mobile: 1.25rem
    ),
    large: (
        desktop: 1.5rem,
        tablet: 1.5rem,
        mobile: 1.5rem
    ),
    extra-large: (
        desktop: 2.4rem,
        tablet: 2.4rem,
        mobile: 2.4rem
    ),
    huge: (
        desktop: 3rem,
        tablet: 3rem,
        mobile: 3rem
    )
);

@each $icon-key, $screen in $icons {
    @if $icon-key == base {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon {
                    width: $icon-value;
                    height: $icon-value;
                }
            }
        }
    } @else {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon--#{$icon-key} {
                    width: $icon-value;
                    height: $icon-value;
                }
            }
        }
    }
}

.icon
{
    display: inline-block;
    fill: none;
    stroke: currentColor;
    stroke-width: $border-width-base;
    vertical-align: middle;
    position: relative;

    &--fill
    {
        fill: currentColor;
        stroke: none;
    }

    &--clear
    {
        stroke: none;
        fill: currentColor;
    }
}

@media only screen and (max-width: $screen-md) {
    @each $icon-key, $screen in $icons {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    @each $icon-key, $screen in $icons {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                    }
                }
            }
        }
    }
}
