$containers: (
    1064: 1064px,
    1000: 1000px,
    900: 900px,
    800: 800px,
    700: 700px,
    600: 600px,
    500: 500px,
    400: 400px,
    450: 450px,
    300: 300px,
    200: 200px,
    max: 1440px
);

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

html {
    @each $container-key, $container-value in $containers {
        .container#{'--' + $container-key} {
            max-width: #{$container-value};
        }
    }
}

@function container($size: '1000') {
    @return map-get($containers, $size);
}

$container-fluid: 100%;
$container-big: 1200px;
$container-base: 1000px;
$container-medium: 856px;
$container-narrow: 600px;
$container-small: 360px;
$container-smaller: 240px;
