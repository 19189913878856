.quick-pay {
    &__video {
        width: 292.5px;
        z-index: 1;
        border-radius: 48px;
        overflow: hidden;
        box-shadow: 0 24px 120px 0 rgba(0, 0, 0, 0.20);
    }

    &__app-section {
        --color-accent-primary: #{color(grey, 200)};

        background-color: #505269;
        padding-block: 0;
        margin-top: -160px;
        color: color(white);
    }

    &__app-container {
        margin-bottom: -100px;
    }

    &__pitch-section {
        z-index: 1;
    }

    &__mobile-image {
        box-shadow: 0 24px 120px 0 rgba(0, 0, 0, 0.20);
        overflow: hidden;
        border-radius: 24px;
        z-index: 2;

        &--medium {
            width: 243px;
        }

        &--large {
            width: 292px;
        }
    }

    #landing-page {
        overflow: hidden;
    }

    .device {
        margin: 0 auto;
        left: 0;

        &__screen {
            width: 100%;
            height: auto;
            position: relative;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            box-shadow: 0 0 0 30px color(white), 0 -12px 40px 0 rgba(0,0,0,.1);
            border-radius: 12px 12px 0 0;
            border: 1px solid color(grey, 50);

            .video-block {
                margin-bottom: 0;
            }
        }

        &__image {
            position: relative;
            max-width: none;
            width: 166%;
            left: -33%;
            bottom: 8px;
            margin-bottom: -50%;
        }
    }

    .image-block {
        &--rounded {
            border-radius: 24px;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    .quick-pay {
        &__app-section {
            margin-top: -120px;
            padding-bottom: 120px;
        }
    }
}
