$form-input-border-radius: 0;
$form-input-border-width: $border-width-base;
$form-input-vertical-padding: 12px;
$form-input-horizontal-padding: 16px;
$form-input-border-color-base: color(grey, 500);
$form-input-font-color-base: var(--primary-color);
$form-input-placeholder-color-base: color(grey, 600);
$form-input-bg-color-base: transparent;
$form-input-font-size-base: $font-size-base;
$form-input-font-weight-base: $font-weight-regular;

$form-input-bg-color-focus: color(white);
$form-input-border-color-focus: color(white);
$form-input-border-width-focus: $form-input-border-width;
$form-input-placeholder-color-focus: var(--background-color);
$form-input-font-color-focus: $form-input-placeholder-color-focus;

$form-input-bg-color-disabled: color(white);
$form-input-border-color-disabled: $form-input-border-color-base;
$form-input-placeholder-color-disabled: color(grey);

$form-input-bg-color-error: rgba(color(red), .03);
$form-input-bg-color-error-focus: color(white);
$form-input-border-color-error: color(red);
$form-input-border-color-error-focus: color(red);
$form-input-placeholder-color-error: rgba(color(red), .3);
$form-input-placeholder-color-error-focus: rgba(color(red), .5);
$form-input-font-color-error: $form-input-font-color-base;

$form-input-bg-color-success: rgba(color(green), .03);
$form-input-bg-color-success-focus: color(white);
$form-input-border-color-success: color(green);
$form-input-border-color-success-focus: color(green);
$form-input-placeholder-color-success: rgba(color(green), .3);
$form-input-placeholder-color-success-focus: rgba(color(green), .5);
$form-input-font-color-success: $form-input-font-color-base;

$form-input-line-height-base: 26px;
$form-input-height-base: 50px;
$form-input-group-width: 100%;
$form-input-group-button-width: 200px;

$form-icon-color-base: $form-input-font-color-base;

.form {
    $form: &;

    &__input {
        display: block;
        padding: $form-input-vertical-padding $form-input-horizontal-padding;
        width: 100%;
        max-width: 100%;
        min-height: $form-input-height-base;
        line-height: $form-input-line-height-base;
        margin-bottom: $form-field-spacing-base;
        border: $form-input-border-width $form-input-border-color-base solid;
        border-radius: $form-input-border-radius;
        background-color: $form-input-bg-color-base;
        color: $form-input-font-color-base;
        font-family: $font-family-regular;
        font-size: $form-input-font-size-base;
        font-weight: $form-input-font-weight-base;
        position: relative;
        transition: $transition-base;
        box-sizing: border-box;

        &--auto {
            width: auto;
        }

        &::placeholder {
            color: $form-input-placeholder-color-base;
        }

        &:focus,
        &:active,
        &--focus,
        &--active {
            color: $form-input-font-color-focus;
            border-color: $form-input-border-color-focus;
            background-color: $form-input-bg-color-focus;

            &::placeholder {
                color: $form-input-placeholder-color-focus;
            }
        }

        &--full {
            width: 100%;
        }

        &--validation {
            &:valid:not(:placeholder-shown) {
                border-color: $form-input-border-color-success;

                &:focus {
                    border-color: $form-input-border-color-success;
                }
            }

            &:invalid:not(:placeholder-shown) {
                border-color: $form-input-border-color-error;

                &:focus {
                    border-color: $form-input-border-color-error;
                }
            }
        }

        &:disabled,
        &--disabled {
            background-color: $form-input-bg-color-disabled;
            pointer-events: none;

            &::placeholder {
                color: $form-input-placeholder-color-disabled;
            }
        }

        &--error {
            background-color: $form-input-bg-color-error;
            border-color: $form-input-border-color-error;

            &::placeholder {
                color: $form-input-placeholder-color-error;
            }

            &:focus,
            &:active,
            &#{$form}__input--focus,
            &#{$form}__input--active {
                border-color: $form-input-border-color-error;
                background-color: $form-input-bg-color-error-focus;

                &::placeholder {
                    color: $form-input-placeholder-color-error-focus;
                }
            }
        }

        &--success {
            background-color: $form-input-bg-color-success;
            border-color: $form-input-border-color-success;

            &::placeholder {
                color: $form-input-placeholder-color-success;
            }

            &:focus,
            &:active,
            &#{$form}__input--focus,
            &#{$form}__input--active {
                border-color: $form-input-border-color-success-focus;
                background-color: $form-input-bg-color-success-focus;

                &::placeholder {
                    color: $form-input-placeholder-color-success-focus;
                }
            }
        }

        &-group {
            display: flex;
            align-items: center;
            width: 100%;
            height: $form-input-height-base;
            position: relative;
            padding: 0 $form-input-horizontal-padding;

            #{$form}__input {
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                margin-bottom: 0;
                border-radius: 0;
                position: relative;
                z-index: 1;

                &:disabled,
                &--disabled {
                    & + #{$form}__input-group-bg {
                        opacity: $form-input-bg-color-disabled;
                        pointer-events: none;
                    }
                }

                &:focus,
                &:active,
                &#{$form}__input--focus,
                &#{$form}__input--active {
                    & + #{$form}__input-group-bg {
                        border-color: $form-input-border-color-focus;
                        background-color: $form-input-bg-color-focus;

                        & + #{$form}__input-group-icon {
                            color: $form-input-font-color-focus;
                        }
                    }
                }

                &--error {
                    & + #{$form}__input-group-bg {
                        background-color: $form-input-bg-color-error;
                        border-color: $form-input-border-color-error;
                    }

                    &:focus,
                    &:active,
                    &#{$form}__input--focus,
                    &#{$form}__input--active {
                        & + #{$form}__input-group-bg {
                            border-color: $form-input-border-color-error;
                            background-color: $form-input-bg-color-error-focus;
                        }
                    }
                }

                &--success {
                    & + #{$form}__input-group-bg {
                        background-color: $form-input-bg-color-success;
                        border-color: $form-input-border-color-success;
                    }

                    &:focus,
                    &:active,
                    &#{$form}__input--focus,
                    &#{$form}__input--active {
                        & + #{$form}__input-group-bg {
                            //box-shadow: 0 0 0 $form-input-border-width-focus $form-input-border-color-success-focus;
                            border-color: $form-input-border-color-success-focus;
                            background-color: $form-input-bg-color-success-focus;
                        }
                    }
                }
            }

            &-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: none;
                box-shadow: 0 0 0 $form-input-border-width $form-input-border-color-base;
                border-radius: $form-input-border-radius;
                background-color: $form-input-bg-color-base;
                z-index: 0;
                transition: $transition-base;

                & + #{$form}__input-group-icon {
                    color: $form-icon-color-base;
                }
            }

            &-input {
                display: flex;
                align-items: center;
                width: $form-input-group-width;
            }

            &-icon {
                display: inline-flex;
                margin-right: 0;
                margin-left: ($form-input-horizontal-padding / 2);
                z-index: 1;
            }

            &--submit {
                padding: 0;

                #{$form}__input {
                    width: calc(#{$form-input-group-width} - #{$form-input-group-button-width} - #{$form-input-horizontal-padding});
                    padding: 0 $form-input-horizontal-padding;
                    border-radius: $form-input-border-radius;

                    &-group {
                        &-bg {
                            width: calc(#{$form-input-group-width} - #{$form-input-group-button-width} - #{$form-input-horizontal-padding});
                            height: $form-input-height-base;
                        }

                        &-button {
                            width: $form-input-group-button-width;
                            margin-left: $form-input-horizontal-padding;
                            margin-right: auto;

                            .button {
                                min-height: $form-input-height-base;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .form {
        $form: &;

        &__input {
            &-group {
                &--submit {
                    flex-direction: column;
                    height: auto;

                    #{$form}__input {
                        width: 100%;

                        &-group {
                            &-bg {
                                width: 100%;
                            }

                            &-input {
                                flex-direction: column;
                                margin-bottom: spacing(sm);
                            }

                            &-button {
                                width: 100%;
                                margin: spacing(sm) 0 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
