.coming-soon
{
    &__content
    {
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;

        .logo
        {
            width: 100px;
            height: 100px;
            margin: 0 auto spacing(md) auto;
        }
    }
}
