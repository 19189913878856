body
{
    display: flex;
    font-family: $font-family-regular;
    font-weight: $font-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
}

.body
{
    &--fixed
    {
        position: fixed;
        width: 100%;
    }

    &--loading
    {
        overflow: hidden;
    }
}
