@tailwind base;
@tailwind components;
@tailwind utilities;

// Root
$images-folder:'../../../public/images/';
$fonts-folder:'../../../public/fonts/';
$sprite: $images-folder + 'sprite.svg';

// Mixins
@import'styles/mixins/index';

// Settings
@import'styles/settings/index';

// Tools
@import'styles/tools/index';

// Base
@import'styles/base/index';

// Plugins
@import'styles/plugins/index';

// Objects
@import'styles/objects/index';

// Components
@import'styles/components/index';

// Layout
@import'styles/layout/index';

// Sections
@import'styles/sections/index';

// Form
@import'styles/form';

// Pages
@import'styles/pages/coming-soon';
@import'styles/pages/projects';
@import'styles/pages/project';
@import'styles/pages/wrapless';
@import'styles/pages/end-to-end';
@import'styles/pages/about';
@import'styles/pages/blog';
@import'styles/pages/quick-pay';
@import'styles/pages/error';
