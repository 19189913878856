.image-block {
    $root: &;

    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    margin-bottom: spacing(md);
    position: relative;

    &--align {
        &-center {
            margin-inline: auto;
        }

        &-right {
            margin-left: auto;
        }
    }
}
