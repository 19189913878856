html {
    &[page="cliche-tales"] {
        --background-color: #{color(grey, 800)};
        --primary-color: #{color(white)};
        --color-accent-primary: #{color(red)};
        --color-accent-negative: #{color(white)};

        .chapters-menu {
            z-index: 1;

            &__content {
                top: -40px;
            }

            &--opened {
                width: auto;
                height: 100%;
                max-height: none;

                .chapters-menu__content {
                    height: auto;
                    overflow: auto;
                    padding: spacing(lg);
                }

                .chapters-menu__item {
                    &[href="#subscribe"] {
                        width: 100%;
                        padding-top: spacing(base);
                        margin-top: spacing(sm);
                        border-top: $border-width-base solid var(--color-accent-primary);
                        font-weight: $font-weight-demi;

                        &:hover {
                            color: var(--color-accent-primary);

                            &:after {
                                display: none;
                            }
                        }

                        &.active {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

html {
    &[page="blog"],
    &[page="blog-post"] {
        --background-color: #{color(khaki, light)};
        --primary-color: #{color(khaki)};
        --button-bg-color: #{color(lemon)};
        --color-accent-primary: #{color(lemon, dark)};
        --color-accent-negative: #{color(white)};
        --highlight-bg-color: #{color(grey, 800)};
        --highlight-color: var(--color-accent-negative);

        .hero {
            &__title {
                &--es {
                    font-size: 92px;

                    @media (max-width: 1120px) {
                        font-size: 80px;
                        line-height: 1.2;
                        padding-inline: spacing(md);
                    }

                    @media (max-width: 768px) {
                        font-size: 60px;
                        line-height: 1.2;
                        padding-inline: spacing(md);
                    }
                }
            }
        }

        .site-frame {
            color: color(khaki, light);
        }

        .blog {
            $spacing: 28px;

            &__number {
                color: var(--color-accent-primary);
            }

            &__language-button {
                &.active {
                    border-color: var(--background-color);
                }
            }

            .hero {
                --hero-height-base: auto;
                padding-top: var(--frame-width);

                &__title {
                    flex-direction: column;

                    .link {
                        font-weight: $font-weight-demi;
                        font-size: $font-size-small;
                        letter-spacing: initial;
                        text-transform: none;
                        border: none;
                        line-height: 1;
                        padding-block: spacing(md) 0;
                    }
                }

                &__content {
                    padding-bottom: 0;
                }
            }

            .heading {
                color: var(--color-accent-primary);
            }

            .heading__sub {
                opacity: 1;
                margin-bottom: spacing(md);
            }

            .spotify {
                > span {
                    margin-bottom: spacing(base);
                }
            }

            .button--simple {
                &:hover {
                    background-color: var(--primary-color);
                }
            }

            .button--split--vert {
                font-weight: $font-weight-medium;
                color: var(--primary-color);
                line-height: 1;

                .button {
                    &__text {
                        color: var(--color-accent-primary);
                    }

                    &__anim span {
                        border-color: transparent;
                    }
                }

                &:hover {
                    .button__text {
                        text-shadow: 0 0 26px var(--color-khaki), 0 0 26px  var(--color-khaki), 0 0 26px  var(--color-khaki), 0 0 26px  var(--color-khaki);
                    }
                }
            }

            &__article {
                //font-weight: $font-weight-medium;

                p, .paragraph {
                    margin-bottom: $spacing;
                    line-height: $line-height-huge;

                    a {
                        display: inline;
                        text-decoration: underline;
                        color: var(--color-accent-primary);
                    }
                }

                .box {
                    background-color: transparent;
                    border: $border-width-base solid $border-color-base;
                    border-radius: 12px;
                    margin-block: spacing(lg);

                    &--bordered {
                        background-color: transparent;
                        border: $border-width-base solid $border-color-base;
                    }

                    iframe {
                        width: calc(100% + (#{spacing(md)} * 2));
                        margin-inline: - spacing(md);
                        margin-top: spacing(base);
                        margin-bottom: - spacing(md);
                    }
                }

                blockquote {
                    border-radius: 12px;
                    padding-left: 56px;
                    padding-block: spacing(md);
                    background-color: transparent;
                    border: $border-width-base solid $border-color-base;
                    font-weight: $font-weight-medium;
                    margin-block: spacing(lg);

                    span {
                        color: var(--color-accent-primary);
                        margin-top: spacing(sm);
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }
    }

    &[page="blog-post"] {
        --background-color: #{color(khaki)};
        --primary-color: #{color(ivory, light)};
        --color-accent-primary: #{color(lemon, dark)};
        --color-accent-negative: #{color(white)};
        --highlight-bg-color: #{color(lemon)};
        --highlight-color: var(--background-color);

        .site-frame {
            color: var(--primary-color);
            //mix-blend-mode: normal;
        }

        .blog {
            &__language-button {
                &.active {
                    border-color: var(--primary-color);
                }
            }

            &__article {
                border-top-color: color(khaki, dark);
                margin-top: spacing(lg);
            }
        }
    }
}

html[page="blog-post"] {
    .blog__subscribe {
        background: color(khaki, dark);
        border: none;
        margin-block: 0 spacing(xl);
    }
}

.blog {
    .hero {
        height: auto;
        min-height: var(--hero-height-base);

        &__content {
            height: inherit;
            min-height: inherit;
        }

        &__title-group {
            position: relative;
        }
    }

    &__logo {
        max-width: calc(100% - var(--frame-width));
    }

    &__number {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: spacing(tn) spacing(sm);
        background: color(grey, 900);
    }

    &__content {
        padding-inline: spacing(lg);
    }

    &__subscribe {
        padding-block: spacing(xl);
        margin-top: spacing(xl);
        border-top: $border-width-base solid color(grey, 700);
    }

    &__language-select {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(sm);
        margin-bottom: spacing(xl);
    }

    &__language-button {
        padding: spacing(xs) spacing(base);
        border: $border-width-base solid transparent;
        cursor: pointer;
        border-radius: 40px;

        &:hover {
            border-color: rgba(white, .25);
        }

        &.active {
            border-color: var(--primary-color);
        }
    }

    &__article {
        padding-block: spacing(xl);
        border-top: $border-width-base solid color(grey, 700);

        &-header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: spacing(sm);
            margin-bottom: spacing(lg);
        }

        p {
            margin-bottom: spacing(base);

            b {
                font-weight: $font-weight-demi;
                line-height: 1em;
            }

            em {
                font-style: italic;
            }
        }

        .box {
            display: flex;
            flex-direction: column;
            padding: spacing(md);
            background: color(grey, 700);
            border: none;
        }

        ol {
            display: flex;
            flex-direction: column;
            counter-reset: step-counter;
            gap: spacing(base);
            padding-block: spacing(sm);
            margin-bottom: spacing(base);

            & > li {
                display: flex;

                &:before {
                    counter-increment: step-counter;
                    content: counter(step-counter);
                    display: inline-flex;
                    font-weight: $font-weight-bold;
                    margin-right: spacing(base);

                }
            }
        }

        blockquote {
            margin: spacing(md) auto;
            font-size: $font-size-base;
            font-style: italic;
            padding: 1.2em 30px 1.2em 2.5em;
            line-height: 1.6;
            position: relative;
            background: color(grey, 900);
        }

        blockquote::before {
            font-family: Arial;
            content: "\201C";
            color: var(--color-accent-primary);
            font-size: $font-size-extra-large;
            position: absolute;
            left: 8px;
            top: -8px;
        }

        blockquote::after {
            content: '';
        }

        blockquote span {
            display: block;
            font-size: $font-size-small;
            color: color(grey, 500);
            font-style: normal;
            margin-top: 1em;
        }

        .box {
            blockquote {
                margin: spacing(sm) 0 spacing(base) 0;
            }
        }

        &--the-end {
            padding-top: 0;
            border-top: none;
        }
    }

    &__gallery {
        display: block;
        width: 100%;
        margin: 0 auto;

        &-singles {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline: spacing(lg);
            padding-block: spacing(xl);
        }

        &-single {
            padding-inline: 0;

            @media (min-width: 992px) {
                padding-inline: spacing(xl);
            }
        }

        &-masonry {
            padding-inline: spacing(lg);
            column-count: 2;
            column-gap: spacing(sm);

            @media (min-width: 768px) {
                column-count: 2;
            }
            @media (min-width: 992px) {
                column-count: 3;
                padding-inline: spacing(xl);
            }

            .brick {
                box-sizing: border-box;
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
                counter-increment: brick-counter;
                margin-bottom: spacing(sm);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transform: scale(.8);
                transition: all 560ms ease-in-out;

                &.loaded {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }

    &__posts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: spacing(lg);
    }

    &__post {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: spacing(lg);
        border-radius: 16px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        min-height: 400px;
        transition: all 320ms ease-in-out;

        &:hover {
            box-shadow: 0 24px 64px rgba(0,0,0,.1);
        }

        &--more {
            border: $border-width-base solid color(khaki, lighter);
            font-size: $font-size-medium;
            font-weight: $font-weight-demi;
            line-height: $line-height-base;
            align-items: center;
            justify-content: center;
            box-shadow: none !important;
        }

        &-title {
            display: block;
            font-size: $font-size-medium;
            font-weight: $font-weight-extra-bold;
            line-height: $line-height-small;
            margin: 0;
            padding: 28px 28px 20px;
            background-color: color(white);
            border-radius: 12px 12px 12px 0;
            text-transform: uppercase;
            position: relative;
            margin-top: auto;
            bottom: - spacing(base);
            left: - spacing(base);
        }

        &-excerpt {
            display: block;
            font-size: $font-size-extra-small;
            text-transform: none;
            font-weight: $font-weight-medium;
        }

        &-meta {
            background-color: color(white);
            position: relative;
            top: spacing(base);
            left: - spacing(base);
            padding: 0 28px 8px;
            border-radius: 0 0 12px 12px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            letter-spacing: .01em;
            font-size: $font-size-tiny;

            &:after {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                right: -24px;
                background-color: transparent;
                border-radius: 12px;
                box-shadow: -12px -12px 0 color(white);
            }
        }

        &-read {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
            padding: spacing(base) spacing(md);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            letter-spacing: .03em;
            font-size: $font-size-tiny;
            text-align: right;
            transition: all 300ms ease-in-out;

            &:after {
                content: '';
                display: block;
                width: 160px;
                height: 160px;
                border-radius: 200px;
                position: absolute;
                border: $border-width-fat solid var(--color-accent-primary);
                top: -24px;
                left: -12px;
                z-index: -1;
                opacity: 0;
                transition: all 300ms ease-in-out;
                transform: scale(.1);
                transform-origin: right bottom;
                backdrop-filter: blur(1px);
            }
        }

        &:hover {
            .blog__post {
                &-read {
                    transform: scale(1.1);

                    &:after {
                        transform: scale(1);
                        background-color: var(--color-accent-primary);
                        opacity: 1;
                    }
                }
            }
        }

        &-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 1s ease-in-out;
            user-select: none;
            pointer-events: none;
        }

        &:hover {
            .blog {
                &__post {
                    &-thumbnail {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    .post-navigation {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            flex-direction: column;
            flex-wrap: wrap;
        }

        &:has(:not(.post-navigation__link--next)),
        &:has(:not(.post-navigation__link--prev)) {
            .post-navigation__link {
                width: 100%;
            }
        }

        &__link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-height: 44px;
            gap: spacing(sm);
            width: fit-content;
            white-space: nowrap;
            padding-inline: spacing(lg);
            padding-block: spacing(lg);
            border-right: $border-width-base solid color(khaki, dark);
            border-top: $border-width-base solid transparent;

            @media (max-width: 768px) {
                border: $border-width-base solid transparent;
                border-bottom-color: color(khaki, dark);
            }

            &:last-child {
                border-right: none;
            }

            &:hover {
                background: color(khaki, dark);
                border-top-color: var(--color-accent-primary);

                @media (max-width: 768px) {
                    border-color: var(--color-accent-primary);
                }
            }

            &--next,
            &--prev {
                font-weight: $font-weight-medium;
                width: 100%;

                &:before,
                &:after {
                    content: '⏮';
                    display: inline-flex;
                    text-transform: uppercase;
                    color: var(--color-accent-primary);
                }

                &:after {
                    display: none;
                }
            }

            &--next {
                &:before {
                    display: none;
                }

                &:after {
                    content: '⏭';
                    display: inline-flex;
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    html {
        &[page="blog"] {
            .blog {
                &__posts {
                    grid-template-columns: 1fr;
                }

                .hero {
                    &__title {
                        font-size: 64px;
                    }
                    &__title-group {
                        padding-top: spacing(xl);
                    }
                }

                &__post {
                    &--more {
                        padding-inline: spacing(base);
                        text-align: center;
                        gap: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: map-get($breakpoints, sm)) {
    .blog {
        &__language-button {
            padding: spacing(tn) spacing(base);
            font-size: $font-size-extra-small;
            font-weight: $font-weight-medium;
        }

        .hero__title {
            font-size: 64px;
        }
    }
    &[page="cliche-tales"] {
        .chapters-menu {
            top: 0;

            &--opened {
                width: auto;
                height: 100%;
                max-height: none;
                top: 0;
                padding-bottom: spacing(lg) * 2;

                .chapters-menu__content {
                    top: spacing(lg);
                    transform: none;
                    gap: spacing(base);
                }
            }
        }

        .blog {
            &__gallery {
                &-masonry {
                    column-count: 1;
                }
            }
        }
    }
}
